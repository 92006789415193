import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const MicrophoneIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} fill="none" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor"><path fillRule="evenodd" d="M8 .667a2.667 2.667 0 0 0-2.667 2.666V8a2.667 2.667 0 1 0 5.334 0V3.333A2.667 2.667 0 0 0 8 .667" clipRule="evenodd" /><path d="M4 6.667a.667.667 0 0 0-1.333 0V8a5.334 5.334 0 0 0 4.668 5.292l-.002.041V14h-2a.667.667 0 0 0 0 1.333h5.334a.667.667 0 0 0 0-1.333h-2v-.667l-.002-.04A5.334 5.334 0 0 0 13.333 8V6.667a.667.667 0 1 0-1.333 0V8a4 4 0 0 1-8 0z" /></g></svg>);
MicrophoneIcon.displayName = "MicrophoneIcon";
export default MicrophoneIcon;