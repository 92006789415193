import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const BarChartSquareIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} fill="none" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M11.892 1.333H4.108c-.352 0-.655 0-.905.02-.263.022-.525.07-.778.198a2 2 0 0 0-.874.874 2 2 0 0 0-.197.778c-.02.25-.02.553-.02.905v7.784c0 .352 0 .655.02.905.021.263.069.525.197.778a2 2 0 0 0 .874.874c.253.128.515.176.778.197.25.02.553.02.905.02h7.784c.352 0 .655 0 .905-.02.263-.021.525-.069.778-.197a2 2 0 0 0 .874-.874c.128-.253.176-.515.197-.778.02-.25.02-.553.02-.905V4.108c0-.352 0-.655-.02-.905a2 2 0 0 0-.197-.778 2 2 0 0 0-.874-.874 2 2 0 0 0-.778-.197c-.25-.02-.553-.02-.905-.02ZM8.667 4.667a.667.667 0 0 0-1.334 0v6.666a.667.667 0 1 0 1.334 0zm2 2c.368 0 .666.298.666.666v4a.667.667 0 1 1-1.333 0v-4c0-.368.299-.666.667-.666M5.333 8c.369 0 .667.298.667.667v2.666a.667.667 0 1 1-1.333 0V8.667c0-.369.298-.667.666-.667" clipRule="evenodd" /></svg>);
BarChartSquareIcon.displayName = "BarChartSquareIcon";
export default BarChartSquareIcon;