import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const ExclamationPointIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1200" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} fill="none" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M698.4 849.6c0 13.199-10.801 24-22.801 24h-144c-13.199 0-22.801-10.801-22.801-24L500.4 54c0-13.199 10.8-24 22.8-24H684c13.2 0 22.802 10.801 22.802 24zm0 296.4c0 13.199-10.801 24-22.801 24h-144c-13.199 0-22.801-10.801-22.801-24l.004-151.2c0-13.199 10.8-24 22.8-24h144c13.2 0 22.802 10.801 22.802 24z" /></svg>);
ExclamationPointIcon.displayName = "ExclamationPointIcon";
export default ExclamationPointIcon;