import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const LineChartUpIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11m-5.293-8.293a1 1 0 0 1-1.414-1.414l3.45-3.45c.085-.085.185-.186.281-.267.111-.094.28-.22.512-.295a1.5 1.5 0 0 1 .928 0c.232.075.4.2.512.295.096.081.196.182.28.266L14 12.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-3.45 3.45c-.085.085-.185.186-.281.267a1.5 1.5 0 0 1-.513.296 1.5 1.5 0 0 1-.926 0 1.5 1.5 0 0 1-.513-.296c-.096-.081-.196-.182-.28-.266L10 11.414z" clipRule="evenodd" /></svg>);
LineChartUpIcon.displayName = "LineChartUpIcon";
export default LineChartUpIcon;