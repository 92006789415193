import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const UserCircleIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor" fillRule="evenodd" clipRule="evenodd"><path d="M7 9.5a5 5 0 1 1 10 0 5 5 0 0 1-10 0" /><path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1M3 12a9 9 0 1 1 15.862 5.824A4.99 4.99 0 0 0 15 16H9a4.99 4.99 0 0 0-3.862 1.824A8.97 8.97 0 0 1 3 12" /></g></svg>);
UserCircleIcon.displayName = "UserCircleIcon";
export default UserCircleIcon;