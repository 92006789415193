import { Hit } from '@algolia/client-search';

import { AssetChange, AssetPrice } from '../research/assets';

export enum HitKind {
    FLASHNOTE = 'flashnote',
    ARTICLE = 'article',
    ASSET = 'asset',
    AUTHOR = 'author',
    DASHBOARD = 'dashboard',
    NEWS = 'news',
    PODCAST = 'podcast',
    PROPOSAL = 'proposal',
    NEWSLETTER = 'newsletter',
    PAGE = 'page',
}

type AuthorData = {
    id: number;
    name: string;
    bio: string;
    kind: HitKind.AUTHOR;
    slug: string;
    title: string;
    imageUrl: string | null;
    featured: boolean;
    twitterUrl: string | null;
    linkedinUrl: string | null;
};

type DashboardData = {
    id: number;
    kind: HitKind.DASHBOARD;
    title: string | null;
    slug: string | null;
    href: string;
    tabName?: string;
    publishedAt: string | null;
    tag: {
        name: string;
        isAsset: boolean;
        slug: string;
    } | null;
};

type NewsData = {
    id: number;
    kind: HitKind.NEWS;
    date: string | null;
    modified: string;
    slug: string;
    type: string;
    link: string;
    title: string;
    content: string;
    author: number;
    excerpt: string;
    imageUrl: string;
};

type AssetData = {
    id: string;
    kind: HitKind.ASSET;
    code: string;
    title: string;
    price: AssetPrice;
    change: AssetChange;
    marketcapRank: number;
    marketcapUsd: number;
    tagline: string | null;
    imageUrl: string;
    type: string | null;
    sector: string | null;
    category: string | null;
    isSupported: boolean;
};

type ProposalData = {
    title: string;
    id: string;
    kind: HitKind.PROPOSAL;
    assetId: string;
    assetCode: string;
    assetName: string;
    status: string;
    importance: string;
    isDraft: boolean | null;
    endDate: string;
    categoryId: string;
    author: string;
    comment: string | null;
    description: string;
    discussionUrl: string;
    url: string;
    txHash: string | null;
    calldata: string | null;
    summary: string | null;
};

type PodcastEpisodeData = {
    id: string;
    slug: string;
    title: string;
    description: string;
    podcastId: string;
    podcastSlug: string;
    podcastTitle: string;
    podcastDescription: string;
    podcastSpotifyId: string;
    websiteUrl: string;
    imageUrl: string;
    duration: number;
    summary: string;
    transcription: string;
    takeaways: string;
    notes: string;
    publishedAt: number;
};

type NewsletterData = {
    id: string;
    title: string;
    subtitle: string;
    content: string;
    kind: HitKind.NEWSLETTER;
    publication: string;
    authors: string[];
    subjectLine: string | null;
    publishDate: number | null;
    previewText: string | null;
};

type PageData = {
    title: string;
    path: string;
    kind: HitKind.PAGE;
    rank: number;
};

type BaseResearchData = {
    id: number;
    title: string;
    slug: string;
    featuredImageUrl: string | null;
    keyDetails: string | null;
    topIdea: string | null;
    keyTakeaways: any;
    content: string | string[];
    sponsors: string[] | null;
    tags: {
        id: number;
        name: string;
        isAsset: boolean;
        slug: string;
        createdAt: string;
        updatedAt: string;
        imageUrl: string | null;
    }[];
    authors: {
        id: number;
        name: string;
        slug: string;
        featured: boolean;
        imageUrl: string | null;
    }[];
};

export type FlashnoteData = BaseResearchData & {
    kind: HitKind.FLASHNOTE;
    flashnotePublishedAt: string;
};

export type ArticleData = BaseResearchData & {
    kind: HitKind.ARTICLE;
    articlePublishedAt: string;
};

type BaseHit = { kind: HitKind };

export type AuthorHit = BaseHit & Hit<AuthorData>;
export type DashboardHit = BaseHit & Hit<DashboardData>;
export type AssetHit = BaseHit & Hit<AssetData>;
export type PodcastEpisodeHit = BaseHit & Hit<PodcastEpisodeData>;
export type NewsHit = BaseHit & Hit<NewsData>;
export type ProposalHit = BaseHit & Hit<ProposalData>;
export type NewsletterHit = BaseHit & Hit<NewsletterData>;
export type ArticleHit = BaseHit & Hit<ArticleData>;
export type FlashnoteHit = BaseHit & Hit<FlashnoteData>;
export type PageHit = BaseHit & Hit<PageData>;

export type SearchHit =
    | AuthorHit
    | DashboardHit
    | NewsHit
    | AssetHit
    | PodcastEpisodeHit
    | ArticleHit
    | FlashnoteHit
    | ProposalHit
    | NewsletterHit
    | PageHit;
