import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const ArrowLeftIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M12.707 5.707a1 1 0 0 0-1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414L7.414 13H19a1 1 0 1 0 0-2H7.414z" /></svg>);
ArrowLeftIcon.displayName = "ArrowLeftIcon";
export default ArrowLeftIcon;