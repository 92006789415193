import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const CheckIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M20.707 5.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9 15.586 19.293 5.293a1 1 0 0 1 1.414 0" clipRule="evenodd" /></svg>);
CheckIcon.displayName = "CheckIcon";
export default CheckIcon;