import { ElementType, PropsWithChildren } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';

import type { DataTestIdProps } from '../../models';
import { TVStyleProps } from '../../style-system';
import type { IconComponent } from '../icon';
import { ChevronDownIcon } from '../icon/icons';
import { LinkOrButton } from '../link-or-button';

import { menuItemStyles } from './menu-item.styles';

type MenuItemStyleProps = TVStyleProps<typeof menuItemStyles>;

type MenuItemProps = PropsWithChildren<
    {
        id?: string;
        submenu?: {
            type: 'accordion' | 'pane';
            component: ElementType;
        };
        icon?: IconComponent;
        href?: string;
        active?: boolean;
        indent?: 0 | 1 | 2;
        onClick?: () => void;
        size?: 'sm' | 'xs';
        rounded?: boolean;
        variant?: Extract<MenuItemStyleProps['variant'], 'link' | 'default' | 'tab'>;
        truncate?: boolean;
        maxW?: MenuItemStyleProps['maxW'];
        intent?: MenuItemStyleProps['intent'];
    } & DataTestIdProps
>;

const MenuItem = ({
    id,
    submenu,
    href,
    children,
    indent,
    active,
    size = 'sm',
    icon: Icon,
    rounded = false,
    onClick,
    variant: variantProp = 'default',
    truncate,
    maxW,
    intent,
    ...rest
}: MenuItemProps) => {
    const accordionId = id ?? href;
    const onClickProps = onClick ? { onClick } : {};
    const isAccordion = submenu?.type === 'accordion';
    const variant = isAccordion ? 'expandable' : variantProp;

    const { accordion, accordionTarget, base, wrapper, toggle, toggleIcon, icon } = menuItemStyles({
        variant,
        rounded,
        truncate,
        maxW,
        intent,
        size,
        ml: indent === 2 ? 2 : 0,
    });

    const link = (
        <RadixNavigationMenu.Link active={active} asChild>
            <LinkOrButton {...onClickProps} id={id} href={href} className={base()}>
                {Icon && <Icon className={icon()} />}
                {children}
            </LinkOrButton>
        </RadixNavigationMenu.Link>
    );

    const renderContent = () => {
        if (accordionId && isAccordion) {
            const Submenu = submenu.component;
            return (
                <RadixAccordion.Root collapsible type="single" defaultValue={active ? accordionId : undefined}>
                    <RadixAccordion.Item value={accordionId} className={accordion()}>
                        <div className={accordionTarget()}>
                            {link}
                            <RadixAccordion.Trigger asChild>
                                <LinkOrButton {...rest} className={toggle()}>
                                    <ChevronDownIcon className={toggleIcon()}></ChevronDownIcon>
                                </LinkOrButton>
                            </RadixAccordion.Trigger>
                        </div>
                        <RadixAccordion.AccordionContent>{<Submenu />}</RadixAccordion.AccordionContent>
                    </RadixAccordion.Item>
                </RadixAccordion.Root>
            );
        }
        if (accordionId && submenu?.type === 'pane') {
            const Submenu = submenu.component;
            return (
                <>
                    {link}
                    {active && (
                        <RadixNavigationMenu.Content forceMount>
                            <Submenu />
                        </RadixNavigationMenu.Content>
                    )}
                </>
            );
        }
        return link;
    };
    return (
        <RadixNavigationMenu.Item className={wrapper()} value={accordionId}>
            {renderContent()}
        </RadixNavigationMenu.Item>
    );
};

export type { MenuItemProps };
export { MenuItem };
