import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const HomeIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} fill="none" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M8.348.89a1.33 1.33 0 0 0-.696 0c-.265.073-.488.248-.666.387l-.05.04-4.574 3.556c-.25.195-.472.367-.637.59a2 2 0 0 0-.318.65c-.074.267-.074.547-.074.865v4.914c0 .352 0 .655.02.905.022.263.07.525.198.778a2 2 0 0 0 .874.874c.253.128.515.176.778.197.25.02.553.02.905.02h7.784c.352 0 .655 0 .905-.02.263-.021.525-.069.778-.197a2 2 0 0 0 .874-.874c.128-.253.176-.515.197-.778.02-.25.02-.553.02-.905V6.978c.001-.318.001-.598-.073-.865a2 2 0 0 0-.318-.65c-.165-.223-.386-.395-.638-.59L9.064 1.316l-.05-.039c-.178-.14-.4-.314-.666-.386ZM6 9.068v4.266h4V9.067c0-.374 0-.56-.073-.703a.67.67 0 0 0-.291-.291C9.493 8 9.306 8 8.933 8H7.067c-.374 0-.56 0-.703.073a.67.67 0 0 0-.291.291C6 8.507 6 8.694 6 9.067Z" clipRule="evenodd" /></svg>);
HomeIcon.displayName = "HomeIcon";
export default HomeIcon;