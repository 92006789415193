enum Role {
    UNAUTHENTICATED,
    UNSUBSCRIBED,
    SUBSCRIBED,
}

/**
 * @param pattern - the route pattern to match
 * @param pathname - the route pathname to navigate to
 * @param requiredRoles - if undefined, anyone can access
 * @param redirects - if undefined, redirect to role-based pattern
 */
type DynamicPath = (p1: string) => string;
type DynamicChildPath = (p1: string, p2: string) => string;
type PathTypes = string | DynamicPath | DynamicChildPath;
export interface IAppRoute<P extends PathTypes = string> {
    pattern: string;
    pathname: P;
    requiredRoles?: Role[];
    redirects?: Partial<Record<Role, string | ((pattern: string) => string)>>;
}

const makeRoute = (requiredRoles?: Role[]) => {
    const rolesParam = requiredRoles ? { requiredRoles } : {};
    return <P extends PathTypes = string>({ pattern, pathname, redirects }: Partial<IAppRoute<P>>) =>
        ({
            pattern,
            pathname: pathname ?? pattern,
            redirects,
            ...rolesParam,
        }) as IAppRoute<P>;
};

const makeBaseRoute = <P extends PathTypes = string>(route: Partial<IAppRoute<P>>) => makeRoute()(route);

const makeUnauthenticatedRoute = <P extends PathTypes = string>(route: Partial<IAppRoute<P>>) =>
    makeRoute([Role.UNAUTHENTICATED])(route);

const makeSubscribedRoute = <P extends PathTypes = string>(route: Partial<IAppRoute<P>>) =>
    makeRoute([Role.SUBSCRIBED])(route);

const makeUnsubscribedRoute = <P extends PathTypes = string>(route: Partial<IAppRoute<P>>) =>
    makeRoute([Role.UNSUBSCRIBED])(route);

const researchToUnlockedRedirect = (path: string) => path.replace('/research', '/unlocked');
const unlockedToResearchRedirect = (path: string) => path.replace('/unlocked', '/research');

// This is verbose but it's required for external references to AppRoutes to be happy
interface IAppRoutes {
    ERROR: IAppRoute;
    NOT_FOUND: IAppRoute;
    LOGOUT: IAppRoute;
    SIGN_UP: IAppRoute;
    SIGN_IN: IAppRoute;
    SIGN_IN_FORGOT: IAppRoute;
    SIGN_IN_FORGOT_TOKEN: IAppRoute<DynamicPath>;
    SIGN_UP_CONTACT_SALES: IAppRoute;
    SIGN_UP_SELECT_PLAN: IAppRoute;
    SIGN_UP_WELCOME: IAppRoute;
    SIGN_UP_WELCOME_FAVORITES: IAppRoute;
    HOME: IAppRoute;
    RESEARCH: IAppRoute;
    RESEARCH_SLUG: IAppRoute<DynamicPath>;
    SETTINGS: IAppRoute;
    SETTINGS_TEAM: IAppRoute;
    SETTINGS_WALLET: IAppRoute;
    SETTINGS_NOTIFICATIONS: IAppRoute;
    SETTINGS_PROFILE: IAppRoute;
    SETTINGS_BILLING: IAppRoute;
    TEAM: IAppRoute;
    TEAM_SLUG: IAppRoute<DynamicPath>;
    PODCASTS: IAppRoute;
    PODCASTS_SLUG: IAppRoute<DynamicPath>;
    PODCASTS_SLUG_EPISODE: IAppRoute<DynamicChildPath>;
    SUPPORT: IAppRoute;
    GOVERNANCE: IAppRoute;
    GLOSSARY: IAppRoute;
    ASSETS: IAppRoute;
    ASSETS_SLUG: IAppRoute<DynamicPath>;
    ASSETS_SLUG_RESEARCH: IAppRoute<DynamicPath>;
    ASSETS_SLUG_GOVERNANCE: IAppRoute<DynamicPath>;
    ASSETS_SLUG_GOVERNANCE_PROPOSAL_ID: IAppRoute<DynamicChildPath>;
    ASSETS_SLUG_ABOUT: IAppRoute<DynamicPath>;
    NEWSLETTERS: IAppRoute;
    NEWSLETTER_ID: IAppRoute<DynamicChildPath>;
    FLASHNOTES: IAppRoute;
    FLASHNOTES_SLUG: IAppRoute<DynamicPath>;
    NOTIFICATIONS: IAppRoute;
    ANALYTICS: IAppRoute;
    ANALYTICS_SLUG: IAppRoute<DynamicPath>;
    UNLOCKED_SLUG: IAppRoute<DynamicPath>;
    FEED: IAppRoute;
}

// @dev - If you add a route, you must add it to the IAppRoutes interface
export const AppRoutes: IAppRoutes = {
    ERROR: makeBaseRoute({ pattern: '/error' }),
    NOT_FOUND: makeBaseRoute({ pattern: '/404' }),
    LOGOUT: makeBaseRoute({ pattern: '/logout' }),
    FEED: makeBaseRoute({ pattern: '/feed' }),
    // Unauthenticated
    SIGN_UP: makeUnauthenticatedRoute({ pattern: '/sign-up' }),
    SIGN_IN: makeUnauthenticatedRoute({ pattern: '/sign-in' }),
    SIGN_IN_FORGOT: makeUnauthenticatedRoute({ pattern: '/sign-in/forgot' }),
    SIGN_IN_FORGOT_TOKEN: makeUnauthenticatedRoute<DynamicPath>({
        pattern: '/sign-in/forgot/:token',
        pathname: (token: string) => `/sign-in/forgot/${token}`,
    }),
    // Unsubscribed
    SIGN_UP_CONTACT_SALES: makeUnsubscribedRoute({
        pattern: '/sign-up/contact-sales',
    }),
    SIGN_UP_SELECT_PLAN: makeUnsubscribedRoute({
        pattern: '/sign-up/select-plan',
    }),
    SIGN_UP_WELCOME: makeUnsubscribedRoute({ pattern: '/sign-up/welcome' }),
    SIGN_UP_WELCOME_FAVORITES: makeUnsubscribedRoute({
        pattern: '/sign-up/welcome/favorites',
    }),
    // Subscribed
    HOME: makeSubscribedRoute({ pattern: '/' }),
    RESEARCH: makeSubscribedRoute({ pattern: '/research' }),
    RESEARCH_SLUG: makeSubscribedRoute({
        pattern: '/research/:slug',
        pathname: slug => `/research/${slug}`,
        redirects: {
            [Role.UNAUTHENTICATED]: researchToUnlockedRedirect,
            [Role.UNSUBSCRIBED]: researchToUnlockedRedirect,
        },
    }),
    SETTINGS: makeSubscribedRoute({ pattern: '/settings' }),
    SETTINGS_TEAM: makeSubscribedRoute({ pattern: '/settings/team' }),
    SETTINGS_WALLET: makeSubscribedRoute({ pattern: '/settings/wallet' }),
    SETTINGS_NOTIFICATIONS: makeSubscribedRoute({
        pattern: '/settings/notifications',
    }),
    SETTINGS_PROFILE: makeSubscribedRoute({ pattern: '/settings/profile' }),
    SETTINGS_BILLING: makeSubscribedRoute({ pattern: '/settings/billing' }),
    TEAM: makeSubscribedRoute({ pattern: '/team' }),
    TEAM_SLUG: makeSubscribedRoute({
        pattern: '/team/:slug',
        pathname: slug => `/team/${slug}`,
    }),
    PODCASTS: makeSubscribedRoute({ pattern: '/podcasts' }),
    PODCASTS_SLUG: makeSubscribedRoute({
        pattern: '/podcasts/:slug',
        pathname: slug => `/podcasts/${slug}`,
    }),
    PODCASTS_SLUG_EPISODE: makeSubscribedRoute({
        pattern: '/podcasts/:slug/episodes/:episodeId',
        pathname: (slug: string, episodeId: string) => `/podcasts/${slug}/episodes/${episodeId}`,
    }),
    SUPPORT: makeSubscribedRoute({ pattern: '/support' }),
    GOVERNANCE: makeSubscribedRoute({ pattern: '/governance' }),
    GLOSSARY: makeSubscribedRoute({ pattern: '/glossary' }),
    ASSETS: makeSubscribedRoute({ pattern: '/assets' }),
    ASSETS_SLUG: makeSubscribedRoute({
        pattern: '/assets/:slug',
        pathname: slug => `/assets/${slug}`,
    }),
    ASSETS_SLUG_RESEARCH: makeSubscribedRoute({
        pattern: '/assets/:slug/research',
        pathname: slug => `/assets/${slug}/research`,
    }),
    ASSETS_SLUG_GOVERNANCE: makeSubscribedRoute({
        pattern: '/assets/:slug/governance',
        pathname: slug => `/assets/${slug}/governance`,
    }),
    ASSETS_SLUG_GOVERNANCE_PROPOSAL_ID: makeSubscribedRoute({
        pattern: '/assets/:slug/governance/:proposalId',
        pathname: (slug: string, proposalId: string) => `/assets/${slug}/governance/${proposalId}`,
    }),
    ASSETS_SLUG_ABOUT: makeSubscribedRoute({
        pattern: '/assets/:slug/about',
        pathname: slug => `/assets/${slug}/about`,
    }),
    FLASHNOTES: makeSubscribedRoute({ pattern: '/flashnotes' }),
    FLASHNOTES_SLUG: makeSubscribedRoute({
        pattern: '/flashnotes/:slug',
        pathname: slug => `/flashnotes/${slug}`,
    }),
    NOTIFICATIONS: makeSubscribedRoute({ pattern: '/notifications' }),
    ANALYTICS: makeSubscribedRoute({ pattern: '/analytics' }),
    ANALYTICS_SLUG: makeSubscribedRoute({
        pattern: '/analytics/:slug',
        pathname: slug => {
            return `/analytics/${slug}`;
        },
    }),
    NEWSLETTER_ID: makeSubscribedRoute({
        pattern: '/newsletters/:slug/:postId',
        pathname: (slug: string, postId: string) => `/newsletters/${slug}/${postId}`,
    }),
    NEWSLETTERS: makeSubscribedRoute({ pattern: '/newsletters' }),
    // Unlocked pages
    UNLOCKED_SLUG: {
        pattern: '/unlocked/:slug',
        pathname: (slug: string) => `/unlocked/${slug}`,
        requiredRoles: [Role.UNAUTHENTICATED, Role.UNSUBSCRIBED],
        redirects: {
            [Role.SUBSCRIBED]: unlockedToResearchRedirect,
        },
    },
};
