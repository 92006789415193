import { createQuery } from 'react-query-kit';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';

import { STALE_TIME } from '../../config/stale-time';
import type { AnyStrapiClient } from '../client/browser';
import type { DataPage } from '../models/entities.model';

type MakeStrapiQueriesOptions = {
    client: AnyStrapiClient;
    getAssetLogo?: (slug: string) => string;
};

const makeStrapiQueries = ({ client, getAssetLogo }: MakeStrapiQueriesOptions) => {
    const useGetDatapage = createQuery({
        queryKey: ['strapi-datapage'],
        fetcher: async () => {
            const { data: datapage } = await client.get.dataPage();

            const categories = uniq(datapage?.tabs.map(tab => tab.category)).filter(Boolean);
            const formatDashboard = (tab: DataPage['tabs'][number]) => {
                const tagSlugs =
                    uniq(tab.dashboards.filter(({ tag }) => tag?.isAsset).map(({ tag }) => tag?.slug)) ?? [];
                const assetTag = tagSlugs?.at?.(0);
                const initialDashboard = tab.dashboards.at(0);

                const href = assetTag
                    ? `/analytics/${assetTag}?dashboard=${initialDashboard?.slug}`
                    : `/analytics/${tab.slug}`;

                return {
                    ...tab,
                    href,
                    assetImage: getAssetLogo?.(assetTag ?? '') ?? null,
                };
            };

            // Temporary exclusion before cutover
            const tabs = datapage?.tabs.filter(tab => tab.slug !== 'spotlight')?.map(formatDashboard);
            const spotlight = datapage?.spotlight?.map?.(formatDashboard);

            return {
                categories: categories as string[],
                bySlug: keyBy(tabs, 'slug'),
                spotlightBySlug: keyBy(spotlight, 'slug'),
            };
        },
        staleTime: STALE_TIME.$1Hour,
    });

    return {
        getDatapage: { use: useGetDatapage },
    };
};

export { makeStrapiQueries };
