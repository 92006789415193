import { forwardRef } from 'react';

import { mapClassNamesToSlots } from '../../style-system';
import { TVComponentWithElementProps } from '../../style-system/models';
import { IconComponent } from '../icon';
import { LinkExternalIcon } from '../icon/icons';

import { LinkBase } from './link.base';
import { linkStyles } from './link.styles';

type LinkProps = TVComponentWithElementProps<'a', typeof linkStyles> & {
    /**
     * Show an inline external link icon
     * @default false
     **/
    leadingIcon?: IconComponent;
    trailingIcon?: IconComponent;
    showExternalIcon?: boolean;
} & { children?: React.ReactNode };

export interface LinkComponent extends React.ForwardRefExoticComponent<LinkProps> {
    Base: typeof LinkBase;
}

const getTrailingIcon = (showExternalIcon: boolean, trailingIcon?: IconComponent) => {
    if (trailingIcon) return trailingIcon;
    if (showExternalIcon) return LinkExternalIcon;
    return;
};

/**
 * @summary A smart link component that handles opening external
 * and internal links; it uses `next/link` for internal links
 * and `anchor` for external links.
 *
 * @description If a link is set to open in na ew tab it will automatically
 * add rel="noopener noreferrer". If you want to override this
 * behavior, you can pass in a custom value to the `rel` prop.
 **/
const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            children,
            variant = 'default',
            intent = 'neutral',
            size = 'xs',
            classNames,
            showExternalIcon = false,
            leadingIcon: LeadingIcon,
            trailingIcon,
            display,
            target,
            rel: relProp,
            ...rest
        },
        ref,
    ) => {
        const { base, icon } = mapClassNamesToSlots(linkStyles, {
            variant,
            size,
            display,
            intent,
            ...rest,
            classNames,
        });
        const ExternalOrTrailingIcon = getTrailingIcon(showExternalIcon, trailingIcon);
        let rel = relProp;
        if (target === '_blank' && !rel) {
            rel = 'noopener noreferrer';
        }

        return (
            <LinkBase {...rest} target={target} rel={rel} className={base} ref={ref}>
                {LeadingIcon ? <LeadingIcon className={icon} /> : null}
                {children}
                {ExternalOrTrailingIcon ? <ExternalOrTrailingIcon className={icon} /> : null}
            </LinkBase>
        );
    },
) as LinkComponent;

Link.displayName = 'Link';
Link.Base = LinkBase;

export type { LinkProps };
export { Link };
