import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const SocialDuneIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} fill="none" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g clipPath="url(#social-dune_svg__a)"><path fill="currentColor" fillOpacity="50%" d="M12 24c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12S0 5.373 0 12c0 6.628 5.373 12 12 12" /><path fill="currentColor" d="M2.08 18.752s7.927-2.596 21.9-7.152c0 0 .764 7.46-6.97 11.352 0 0-3.814 1.828-7.998.655 0 0-4.167-.786-6.932-4.855" /></g><defs><clipPath id="social-dune_svg__a"><path fill="currentColor" d="M0 0h24v24H0z" /></clipPath></defs></svg>);
SocialDuneIcon.displayName = "SocialDuneIcon";
export default SocialDuneIcon;